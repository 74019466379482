@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
}
h1 {
  font-family: "Playfair Display", serif;
}
h2 {
  font-family: "Playfair Display", serif;
}
h3 {
  font-family: "Playfair Display", serif;
}

.flag-icon {
  animation: wave 2s infinite linear;
}

@keyframes wave {
  0% {
      transform: rotate(0deg);
  }
  25% {
      transform: rotate(10deg);
  }
  50% {
      transform: rotate(0deg);
  }
  75% {
      transform: rotate(-10deg);
  }
  100% {
      transform: rotate(0deg);
  }
}
/*Loading spinner*/
.loading{
  position: absolute;
  left: 50%;
  top: 35%;
}
.loading svg polyline {
  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.loading svg polyline#back {
  fill: none;
  stroke: #052049;
}

.loading svg polyline#front {
  fill: none;
  stroke: #f6f9fc;
  stroke-dasharray: 48, 144;
  stroke-dashoffset: 192;
  animation: dash_682 1.4s linear infinite;
}

@keyframes dash_682 {
  72.5% {
    opacity: 0;
  }

  to {
    stroke-dashoffset: 0;
  }
}


@keyframes s3 {
  100% {
    transform: rotate(1turn)
  }
}
/*For bullets*/
.custom-bullets {
  list-style: none; /* Remove default bullets */
}

.custom-bullets li:before {
  content: "•"; 
  color: "#052049"; 
  font-size: 1.2em; 
  margin-right: 0.5em; 
}

#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 30px; /* Reduced width */
  cursor: pointer;
  margin: auto;
  display: block;
  height: calc(3px * 3 + 8px * 2); /* Adjusted height */
}

.bar {
  position: absolute;
  left: 0;
  right: 0;
  height: 3px; /* Reduced height */
  border-radius: calc(3px / 2); /* Adjusted border radius */
  background: #2e475d;
  color: inherit;
  opacity: 1;
  transition: none 0.35s cubic-bezier(.5,-0.35,.35,1.5) 0s;
}

/***** Tornado Animation *****/

.bar--top {
  bottom: calc(50% + 8px + 3px / 2); /* Adjusted position */
  transition-property: bottom,transform;
  transition-delay: calc(0s + 0.35s) * .6;
}

.bar--middle {
  top: calc(50% - 3px / 2); /* Adjusted position */
  transition-property: opacity,transform;
  transition-delay: calc(0s + 0.35s * .3);
}

.bar--bottom {
  top: calc(50% + 8px + 3px / 2); /* Adjusted position */
  transition-property: top,transform;
  transition-delay: 0s;
}

#checkbox:checked + .toggle .bar--top {
  transform: rotate(-135deg);
  transition-delay: 0s;
  bottom: calc(50% - 3px / 2); /* Adjusted position */
}

#checkbox:checked + .toggle .bar--middle {
  opacity: 0;
  transform: rotate(-135deg);
  transition-delay: calc(0s + 0.35s * .3);
}

#checkbox:checked + .toggle .bar--bottom {
  top: calc(50% - 3px / 2); /* Adjusted position */
  transform: rotate(-225deg);
  transition-delay: calc(0s + 0.35s * .6);
}

/*custom bullets*/
.custom-bullets li:before {
  content: "•"; 
  color: #FF7235; 
  font-size: 1.2em; 
  margin-right: 0.5em; 
}

/*Slider smjestaj CSS*/
.slick-next{
  right: -50px!important;
}
.slick-prev{
  left: -50px!important;
}

.slick-prev, .slick-next {
  width: 50px!important;
  height: 50px!important;
  color:black!important;
  background-color: #f6f9fc!important;
}
.slick-prev:hover,
.slick-next:hover {
  transition: 0.3s;
  color: #FF7235 !important;
}

/*Live animation background for Vision/Mision*/
@keyframes move {
  100% {
      transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}

.background {
  object-fit: contain;
  position: absolute;
  width: 100%;
  height: 200vh;
  z-index: -1;
  background: #00345c;
  overflow: hidden;
}

.background span {
  width: 1vmin;
  height: 1vmin;
  border-radius: 1vmin;
  backface-visibility: hidden;
  position: absolute;
  animation: move;
  animation-duration: 45;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}


.background span:nth-child(0) {
  color: #a6a5a7;
  top: 3%;
  left: 54%;
  animation-duration: 39s;
  animation-delay: -21s;
  transform-origin: -6vw -6vh;
  box-shadow: -2vmin 0 0.6027123156735801vmin currentColor;
}
.background span:nth-child(1) {
  color: #a6a5a7;
  top: 9%;
  left: 83%;
  animation-duration: 42s;
  animation-delay: -27s;
  transform-origin: 22vw -3vh;
  box-shadow: -2vmin 0 0.5877111946948406vmin currentColor;
}
.background span:nth-child(2) {
  color: #a6a5a7;
  top: 11%;
  left: 48%;
  animation-duration: 10s;
  animation-delay: -44s;
  transform-origin: 13vw 11vh;
  box-shadow: -2vmin 0 0.8721260526082624vmin currentColor;
}
.background span:nth-child(3) {
  color: #a6a5a7;
  top: 59%;
  left: 98%;
  animation-duration: 42s;
  animation-delay: -48s;
  transform-origin: 15vw 0vh;
  box-shadow: 2vmin 0 0.8965405142036067vmin currentColor;
}
.background span:nth-child(4) {
  color: #b0b0b0;
  top: 42%;
  left: 25%;
  animation-duration: 9s;
  animation-delay: -27s;
  transform-origin: 9vw -17vh;
  box-shadow: -2vmin 0 0.8282195456033161vmin currentColor;
}
.background span:nth-child(5) {
  color: #b0b0b0;
  top: 71%;
  left: 68%;
  animation-duration: 19s;
  animation-delay: -43s;
  transform-origin: 1vw 7vh;
  box-shadow: 2vmin 0 0.6031761497520098vmin currentColor;
}
.background span:nth-child(6) {
  color: #b0b0b0;
  top: 21%;
  left: 52%;
  animation-duration: 55s;
  animation-delay: -14s;
  transform-origin: 9vw 14vh;
  box-shadow: 2vmin 0 0.42716589806496263vmin currentColor;
}
.background span:nth-child(7) {
  color: #b0b0b0;
  top: 69%;
  left: 90%;
  animation-duration: 41s;
  animation-delay: -9s;
  transform-origin: -10vw -1vh;
  box-shadow: 2vmin 0 1.0948053509336977vmin currentColor;
}
.background span:nth-child(8) {
  color: #929090;
  top: 65%;
  left: 33%;
  animation-duration: 41s;
  animation-delay: -50s;
  transform-origin: -17vw -18vh;
  box-shadow: 2vmin 0 0.7507973344760857vmin currentColor;
}
.background span:nth-child(9) {
  color: #b0b0b0;
  top: 55%;
  left: 80%;
  animation-duration: 27s;
  animation-delay: -36s;
  transform-origin: 19vw 14vh;
  box-shadow: 2vmin 0 0.6221047968126885vmin currentColor;
}
.background span:nth-child(10) {
  color: #929090;
  top: 53%;
  left: 83%;
  animation-duration: 17s;
  animation-delay: -26s;
  transform-origin: -12vw -18vh;
  box-shadow: 2vmin 0 0.40352526567187263vmin currentColor;
}
.background span:nth-child(11) {
  color: #b0b0b0;
  top: 88%;
  left: 21%;
  animation-duration: 17s;
  animation-delay: -46s;
  transform-origin: -3vw -18vh;
  box-shadow: 2vmin 0 0.692487388269523vmin currentColor;
}
.background span:nth-child(12) {
  color: #a6a5a7;
  top: 46%;
  left: 47%;
  animation-duration: 27s;
  animation-delay: -22s;
  transform-origin: 10vw -9vh;
  box-shadow: 2vmin 0 0.5017305183604666vmin currentColor;
}
.background span:nth-child(13) {
  color: #929090;
  top: 85%;
  left: 10%;
  animation-duration: 28s;
  animation-delay: -47s;
  transform-origin: -14vw -1vh;
  box-shadow: -2vmin 0 0.7963702431219495vmin currentColor;
}
.background span:nth-child(14) {
  color: #929090;
  top: 15%;
  left: 11%;
  animation-duration: 30s;
  animation-delay: -50s;
  transform-origin: 22vw -11vh;
  box-shadow: -2vmin 0 0.4201340107166822vmin currentColor;
}
.background span:nth-child(15) {
  color: #a6a5a7;
  top: 26%;
  left: 37%;
  animation-duration: 34s;
  animation-delay: -24s;
  transform-origin: 8vw -4vh;
  box-shadow: -2vmin 0 0.713704075828985vmin currentColor;
}
.background span:nth-child(16) {
  color: #a6a5a7;
  top: 31%;
  left: 79%;
  animation-duration: 43s;
  animation-delay: -20s;
  transform-origin: 20vw 14vh;
  box-shadow: -2vmin 0 0.41865790348718424vmin currentColor;
}
.background span:nth-child(17) {
  color: #929090;
  top: 84%;
  left: 5%;
  animation-duration: 18s;
  animation-delay: -41s;
  transform-origin: -1vw 15vh;
  box-shadow: 2vmin 0 1.1203101199210719vmin currentColor;
}
.background span:nth-child(18) {
  color: #a6a5a7;
  top: 33%;
  left: 85%;
  animation-duration: 34s;
  animation-delay: -30s;
  transform-origin: -14vw -8vh;
  box-shadow: -2vmin 0 1.1713755800160792vmin currentColor;
}
.background span:nth-child(19) {
  color: #b0b0b0;
  top: 64%;
  left: 73%;
  animation-duration: 26s;
  animation-delay: -48s;
  transform-origin: -19vw 25vh;
  box-shadow: 2vmin 0 0.37398314349834716vmin currentColor;
}
.background span:nth-child(20) {
  color: #a6a5a7;
  top: 90%;
  left: 10%;
  animation-duration: 30s;
  animation-delay: -14s;
  transform-origin: 22vw 16vh;
  box-shadow: -2vmin 0 0.6885171314181757vmin currentColor;
}
.background span:nth-child(21) {
  color: #a6a5a7;
  top: 60%;
  left: 86%;
  animation-duration: 43s;
  animation-delay: -8s;
  transform-origin: 4vw -22vh;
  box-shadow: -2vmin 0 0.330018827987673vmin currentColor;
}
.background span:nth-child(22) {
  color: #b0b0b0;
  top: 100%;
  left: 53%;
  animation-duration: 39s;
  animation-delay: -22s;
  transform-origin: 25vw 11vh;
  box-shadow: -2vmin 0 1.2110331920689175vmin currentColor;
}
.background span:nth-child(23) {
  color: #929090;
  top: 23%;
  left: 42%;
  animation-duration: 31s;
  animation-delay: -36s;
  transform-origin: 7vw 4vh;
  box-shadow: 2vmin 0 0.41376401752141834vmin currentColor;
}
.background span:nth-child(24) {
  color: #b0b0b0;
  top: 15%;
  left: 72%;
  animation-duration: 24s;
  animation-delay: -21s;
  transform-origin: 19vw 12vh;
  box-shadow: -2vmin 0 0.9783600405184745vmin currentColor;
}
.background span:nth-child(25) {
  color: #929090;
  top: 41%;
  left: 48%;
  animation-duration: 10s;
  animation-delay: -45s;
  transform-origin: -17vw -7vh;
  box-shadow: -2vmin 0 1.0559149616260173vmin currentColor;
}
.background span:nth-child(26) {
  color: #929090;
  top: 55%;
  left: 31%;
  animation-duration: 36s;
  animation-delay: -3s;
  transform-origin: 2vw 0vh;
  box-shadow: 2vmin 0 0.6319821596504684vmin currentColor;
}
.background span:nth-child(27) {
  color: #b0b0b0;
  top: 42%;
  left: 90%;
  animation-duration: 54s;
  animation-delay: -35s;
  transform-origin: -6vw 14vh;
  box-shadow: 2vmin 0 1.1633406226493594vmin currentColor;
}
.background span:nth-child(28) {
  color: #b0b0b0;
  top: 48%;
  left: 41%;
  animation-duration: 22s;
  animation-delay: -8s;
  transform-origin: -17vw 7vh;
  box-shadow: 2vmin 0 0.5999603195716892vmin currentColor;
}





code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
